import React, { useState, useEffect, useRef } from 'react'
import { useMutation, gql, ApolloError } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { addFile, clearFiles, removeFile } from 'src/slices/uploadSlice'
import Select, { StylesConfig } from 'react-select'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Icon from '@mdi/react'
import { FaChevronUp, FaRegCircle } from 'react-icons/fa'
import { mdiPlus, mdiMinus } from '@mdi/js'
import axios from 'axios'
import './CreateNewOrder.css'
import { AnimatePresence, motion } from 'framer-motion'
import FileUpload from 'src/components/FileManager/FileUpload'
import FileList from 'src/components/FileManager/FileList'
import { FileValue } from 'src/types'
import fs from 'fs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

interface StudentData {
  user: {
    id: string
    firstName: string
    lastName: string
    userName: string
    email: string
    dateOfBirth: string
  } | null
}

interface PaperOption {
  value: string
  label: string
}

interface OrderData {
  id: string
  paperType: string
  pages: number
  dueDate: string
  instructions: string
}

interface FileData {
  id: string
  name: string
  size: string
  url: string
  mimeType: string
}

// Mutation to create an order
const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      success
      message
      order {
        id
        studentId
        instructions
        paperType
        numberOfPages
        dueDate
        totalAmount
        depositAmount
        status
        uploadedFiles {
          id
          name
          url
          size
          mimeType
        }
      }
    }
  }
`

const CreateNewOrder: React.FC<StudentData> = ({ user }) => {
  const [selectedPaperType, setSelectedPaperType] =
    useState<PaperOption | null>(null)
  const [numberOfPages, setNumberOfPages] = useState<number>(1)
  const [dueDate, setDueDate] = useState<string>('')
  const [showSummary, setShowSummary] = useState(false)
  const [rotateSummary, setShowRotateSummary] = useState(false)
  const [content, setContent] = useState<string>('')
  const [uploadedFiles, setUploadedFiles] = useState<FileValue[]>([])
  const editorRef = useRef<ReactQuill | null>(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const paperOptions: PaperOption[] = [
    { value: 'ESSAY', label: 'Essay (any type)' },
    { value: 'ADMISSION_ESSAY', label: 'Admission essay' },
    { value: 'ANNOTATED_BIBLIOGRAPHY', label: 'Annotated bibliography' },
    { value: 'ARGUMENTATIVE_ESSAY', label: 'Argumentative essay' },
    { value: 'ARTICLE_REVIEW', label: 'Article review' },
    { value: 'BOOK_MOVIE_REVIEW', label: 'Book/movie review' },
    { value: 'BUSINESS_PLAN', label: 'Business plan' },
    { value: 'PRESENTATION_SPEECH', label: 'Presentation speech' },
    { value: 'RESEARCH_PROPOSAL', label: 'Research proposal' },
    { value: 'CASE_STUDY', label: 'Case study' },
    { value: 'CRITICAL_THINKING', label: 'Critical thinking' },
    { value: 'COURSE_WORK', label: 'Course work' },
    { value: 'TERM_PAPER', label: 'Term paper' },
    {
      value: 'THESIS_DISSERTATION_CHAPTER',
      label: 'Thesis/Dissertation chapter',
    },
    { value: 'CREATIVE_WRITING', label: 'Creative writing' },
    { value: 'OTHER', label: 'Other' },
  ]

  const pricePerPage = 20
  const baseUrl =
    process.env.REACT_APP_BACKEND_URL ||
    'https://anyday-backend-gcloudrun-969666510139.us-central1.run.app'

  const [
    createOrder,
    { data: orderData, loading: orderLoading, error: orderError },
  ] = useMutation(CREATE_ORDER_MUTATION, {
    //Apollo update
    update(cache, { data: { createOrder } }) {
      console.log('createOrder.order:', createOrder.order)

      if (createOrder.success) {
        cache.modify({
          fields: {
            getOrders(existingOrders = []) {
              const newOrderRef = cache.writeFragment({
                data: createOrder.order,
                fragment: gql`
                  fragment NewOrder on Order {
                    id
                    studentId
                    instructions
                    paperType
                    numberOfPages
                    dueDate
                    uploadedFiles {
                      id
                      name
                      url
                      size
                      mimeType
                    }
                  }
                `,
              })
              return [...existingOrders, newOrderRef]
            },
          },
        })
      }
    },
    onCompleted: () => {
      navigate('/')
    },
  })

  const validateContent = () =>
    content.replace(/<[^>]*>/g, '').trim().length > 0

  const handleFontSizeChange = (value: string | number) => {
    if (editorRef.current) {
      const quill = editorRef.current.getEditor()
      quill.format('size', value)
    }
  }

  const generateSummary = (): string => {
    const plainText = content.replace(/<[^>]*>/g, '').trim()
    const sentences = plainText.split('. ').slice(0, 3)
    return sentences.join('. ') + (sentences.length > 3 ? '...' : '')
  }

  const toggleShowSummary = (): void => {
    setShowSummary(!showSummary)
    setShowRotateSummary(!rotateSummary)
  }

  const handleFileUpload = async (files: File[]) => {
    try {
      const formData = new FormData()
      files.forEach((file) => formData.append('files', file))

      const response = await axios.post(
        `${baseUrl}/api/upload/files`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      )

      if (response.data.uploadedFiles) {
        const newFiles = response.data.uploadedFiles.map((file: FileData) => ({
          id: file.id,
          name: file.name,
          size: file.size,
          mimeType: file.mimeType,
          url: file.url,
        }))
        setUploadedFiles((prev) => [...prev, ...newFiles])
        newFiles.forEach((file: FileValue) => dispatch(addFile(file)))
      } else {
        console.error('Error: No files returned from server')
      }
    } catch (error) {
      console.error('Error uploading files:', error)
      alert('File upload failed. Please try again.')
    }
  }

  const handleRemoveFile = (id: number) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id))
    dispatch(removeFile(id))
  }

  const handleClearFiles = () => {
    setUploadedFiles([])
    dispatch(clearFiles())
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!validateContent()) {
      alert('Order instructions cannot be empty!')
      return
    }

    try {
      let fileData: FileData[] = []

      if (uploadedFiles.length > 0) {
        const url = `${baseUrl}/api/upload/files`
        const response = await axios.post(url, uploadedFiles, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        // console.log(response.data.uploadedFiles)
        if (response.data.uploadedFiles) {
          // console.log(response.data.files)
          fileData = response.data.uploadedFiles.map((file: FileData) => ({
            id: file.id,
            name: file.name,
            url: file.url,
            size: file.size ? file.size.toString() : '0',
            mimeType: file.mimeType,
          }))
        } else {
          console.error('No uploadedFiles field in response:', response.data)
        }
      }

      const totalAmount = numberOfPages * pricePerPage
      const depositAmount = totalAmount * 0.5

      await createOrder({
        variables: {
          input: {
            instructions: content,
            paperType: selectedPaperType?.value,
            numberOfPages,
            dueDate,
            depositAmount,
            uploadedFiles: fileData,
          },
        },
      })

      console.log('Order created successfully!')
    } catch (error) {
      if (error instanceof ApolloError) {
        console.error('ApolloError:', error.message)
      } else {
        console.error('Unexpected Error:', error)
      }
    }
  }

  return (
    <div className="registration-container h-full">
      <div
        className="flex items-start justify-start gap-[5px]"
        id="text-editor"
      >
        <div className="ml-[35px] px-[35px]">
          <form onSubmit={handleSubmit}>
            <div className="flex justify-evenly items-start gap-4">
              {/* Editor */}
              <div className="bg-on-primary editor-container shadow-2xl">
                <ReactQuill
                  ref={editorRef}
                  theme="snow"
                  modules={{
                    toolbar: {
                      container: [
                        [{ size: ['small', false, 'large', 'huge'] }],
                        [{ font: [] }],
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        [{ align: [] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ color: [] }, { background: [] }],
                        ['link', 'image', 'video', 'formula'],
                        ['clean'],
                      ],
                    },
                  }}
                  placeholder="Add instructions here or leave a ❤️"
                  value={content}
                  onChange={setContent}
                />
              </div>

              {/* Order Summary */}
              <div className="order-summary bg-secondary p-4 rounded-md shadow-lg max-w-[400px]">
                <div
                  className="flex items-center justify-between px-4 py-2 border-b cursor-pointer text-on-primary"
                  onClick={toggleShowSummary}
                >
                  <h3 className="text-lg font-medium">Order Summary</h3>
                  <motion.div animate={{ rotate: rotateSummary ? 180 : 0 }}>
                    <FaChevronUp className="text-sm" />
                  </motion.div>
                </div>
                {showSummary && (
                  <motion.div
                    className="p-4 text-sm text-on-primary"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { opacity: 1, height: 'auto' },
                      collapsed: { opacity: 0, height: 0 },
                    }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                  >
                    <p>
                      <strong>Pages:</strong> {numberOfPages}
                    </p>
                    <p>
                      <strong>Due Date:</strong> {dueDate}
                    </p>
                    <p>
                      <strong>Instructions:</strong> {generateSummary()}
                    </p>
                    {/* Files Table */}
                    <div className="mt-4">
                      <h4 className="text-lg font-semibold">Uploaded Files</h4>
                      {uploadedFiles.length > 0 ? (
                        <table className="min-w-full bg-gray-50 border border-gray-200 rounded mt-2">
                          <thead>
                            <tr>
                              <th className="py-2 px-4 text-left">File Name</th>
                              <th className="py-2 px-4">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {uploadedFiles.map((file) => (
                              <tr
                                key={file.id}
                                className="bg-error-container text-on-error-container"
                              >
                                <td className="py-2 px-4">{file.name}</td>
                                <td className="py-2 px-4 text-center">
                                  <button
                                    onClick={() => handleRemoveFile(file.id)}
                                    className="text-error p-1 rounded"
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p className="text-gray-500 mt-2">No files uploaded.</p>
                      )}
                    </div>
                  </motion.div>
                )}
              </div>
            </div>

            <div className="flex flex-col items-start justify-center gap-[15px] mt-[35px]">
              <label
                htmlFor="paper-type"
                className="block text-sm font-medium text-gray-700"
              >
                Paper Type
              </label>
              <Select
                inputId="paper-type"
                options={paperOptions}
                value={selectedPaperType}
                onChange={setSelectedPaperType}
                className="w-[270px]"
              />
              <label
                htmlFor="pages"
                className="block text-sm font-medium text-gray-700"
              >
                Number of Pages
              </label>
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() =>
                    setNumberOfPages((prev) => Math.max(prev - 1, 1))
                  }
                  className="shadow-md bg-on-primary mr-[15px] p-2 text-gray-500 rounded cursor-pointer"
                >
                  <Icon path={mdiMinus} size={1} />
                </button>
                <span className="mx-2">{numberOfPages}</span>
                <button
                  type="button"
                  onClick={() => setNumberOfPages((prev) => prev + 1)}
                  className="shadow-md bg-on-primary ml-[15px] p-2 text-gray-500 rounded cursor-pointer"
                >
                  <Icon path={mdiPlus} size={1} />
                </button>
              </div>
              <label
                htmlFor="due-date"
                className="block text-sm font-medium text-gray-700"
              >
                Due Date
              </label>
              <input
                type="datetime-local"
                id="due-date"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
                required
                className="required:border-error invalid:border-error shadow border-0 focus:border-1 rounded w-[270px] py-2 px-3"
              />
              <button
                type="submit"
                className="mt-4 px-7 py-2 text-sm rounded-md shadow-sm text-white bg-warning hover:border-[2px] hover:border-on-primary mb-[35px] text-on-primary"
              >
                Submit
              </button>
            </div>
            <div className="mb-[35px]">
              <FileUpload onUpload={handleFileUpload} editorRef={editorRef} />
            </div>
            <FileList
              propFiles={uploadedFiles}
              onRemove={handleRemoveFile}
              onClearFiles={handleClearFiles}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreateNewOrder
