import React from 'react'

const PageNotFound: React.FC = () => {
  return (
    <div className="text-center text-xl text-secondary">
      The page you are looking for could not be found
    </div>
  )
}

export default PageNotFound
