import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

const PaymentSuccess: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const orderId = searchParams.get('orderId')

  useEffect(() => {
    if (orderId) {
      alert('Payment confirmed and order is being processed.')
      navigate(`/orders/${orderId}`)
    }
  }, [orderId, navigate])

  return (
    <div>
      <h1>Payment Success</h1>
      <p>Your payment has been processed successfully!</p>
    </div>
  )
}

export default PaymentSuccess
