import React from 'react'
import DashboardHeader from './DashboardHeader'
import { Outlet } from 'react-router-dom'

const DashboardLayout = () => (
  <div>
    <DashboardHeader />
    <main>
      <Outlet />
    </main>
  </div>
)

export default DashboardLayout
