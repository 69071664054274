import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import OrderDetailPage from './OrderDetailPage'
import { Order } from 'src/types'

interface OrderDetailPageWrapperProps {
  tasks: Order[]
  onDelete: (id: string) => void
  onUpdate: (updatedTask: Order) => void
}

const OrderDetailPageWrapper: React.FC<OrderDetailPageWrapperProps> = ({
  tasks,
  onDelete,
  onUpdate,
}) => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()

  // Find the task based on the ID in the URL
  const task = tasks.find((task) => task.id === id)

  if (!task) {
    // If task not found, navigate to a not found page or show an error
    navigate('/not-found') // Or replace with a fallback page
    return null
  }

  return <OrderDetailPage task={task} onDelete={onDelete} onUpdate={onUpdate} />
}

export default OrderDetailPageWrapper
