import React, { createContext, useContext, useState, ReactNode } from 'react'

// Define the shape of the user object
interface User {
  id: string
  firstName: string
  lastName: string
  userName: string
  email: string
  dateOfBirth: string
}

// Define the shape of the context value
interface UserContextValue {
  user: User | null
  setUser: React.Dispatch<React.SetStateAction<User | null>>
  loginState: (token: string, user: User) => void
  logoutState: () => void
}

const UserContext = createContext<UserContextValue | undefined>(undefined)

interface UserProviderProps {
  children: ReactNode
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)

  const loginState = (token: string, user: User) => {
    localStorage.setItem('token', token)
    setUser(user)
  }

  const logoutState = () => {
    localStorage.removeItem('token')
    setUser(null)
  }

  const value = { user, setUser, loginState, logoutState }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

// Custom hook for consuming the UserContext
export const useUserContext = (): UserContextValue => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}

export default UserContext
