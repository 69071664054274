import React from 'react'
import KanbanBoard from './KanbanBoard'
import { Orders } from 'src/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'

interface ProgressTabProps {
  tasks: Orders
}

const ProgressTab: React.FC<ProgressTabProps> = ({ tasks }) => {
  return (
    <div className="p-4 bg-gradient-to-r to-primary from-secondary-container h-full">
      {/* <div className='mt-[100px] ml-[100px]'>
        <button className=' px-4 py-3 rounded-md max-w-[250px] ml-[135px] hover:opacity-80 border-[1px] bg-on-primary'>
          <div className='text-on-primary-container'>
            <FontAwesomeIcon icon={faCirclePlus} className='text-[80px] mt-[20px]'/>
          <div className='text-xl mt-[20px]'>Create your order</div>
          </div>
        </button>
      </div> */}
      <KanbanBoard tasks={tasks} />
    </div>
  )
}

export default ProgressTab
