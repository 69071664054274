import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Order } from 'src/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faTrash,
  faCreditCard,
  faPaperclip,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons'
import EditOrderModal from './EditOrderModal'

const baseUrl =
  process.env.REACT_APP_BACKEND_URL ||
  'https://anyday-backend-gcloudrun-969666510139.us-central1.run.app'

// Load Stripe with your public key
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY

if (!stripePublicKey) {
  console.error('Stripe public key is not set')
}
const stripePromise = loadStripe(stripePublicKey as string)

interface OrderDetailPageProps {
  task: Order
  onDelete: (id: string) => void
  onUpdate: (updatedTask: Order) => void
}

interface PaperOption {
  value: string
  label: string
}

interface OrderStatus {
  value: string
  label: string
}

interface CustomPaymentRequest {
  orderId: string
  amount: number
  paymentType: string
}

const OrderDetailPage: React.FC<OrderDetailPageProps> = ({
  task,
  onDelete,
  onUpdate,
}) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [paymentType, setPaymentType] = useState<'deposit' | 'full'>('deposit')
  const navigate = useNavigate()

  useEffect(() => {
    document.body.classList.toggle(
      'overflow-hidden',
      showEditModal || showDeleteModal || showPaymentModal,
    )
    return () => document.body.classList.remove('overflow-hidden')
  }, [showEditModal, showDeleteModal, showPaymentModal])

  const closeModal = () => {
    setShowDeleteModal(false)
    setShowEditModal(false)
    setShowPaymentModal(false)
  }

  const paperOptions: PaperOption[] = [
    { value: 'essay', label: 'Essay (any type)' },
    { value: 'admission_essay', label: 'Admission essay' },
    { value: 'annotated_bibliography', label: 'Annotated bibliography' },
    { value: 'argumentative_essay', label: 'Argumentative essay' },
    { value: 'article_review', label: 'Article review' },
    { value: 'book_movie_review', label: 'Book/movie review' },
    { value: 'business_plan', label: 'Business plan' },
    { value: 'presentation_speech', label: 'Presentation speech' },
    { value: 'research_proposal', label: 'Research proposal' },
    { value: 'case_study', label: 'Case study' },
    { value: 'critical_thinking', label: 'Critical thinking' },
    { value: 'course_work', label: 'Course work' },
    { value: 'term_paper', label: 'Term paper' },
    {
      value: 'thesis_dissertation_chapter',
      label: 'Thesis/Dissertation chapter',
    },
    { value: 'creative_writing', label: 'Creative writing' },
    { value: 'research_paper', label: 'Research Paper' },
    { value: 'other', label: 'Other' },
  ]

  const getPaperTypeLabel = (paperType: string) => {
    const paper = paperOptions.find((option) => option.value === paperType)
    return paper ? paper.label : paperType
  }

  const orderStatus: OrderStatus[] = [
    { value: 'PENDING', label: 'Pending payment confirmation 💳' },
    { value: 'IN_PROGRESS', label: 'In Progress 👩🏼‍🔬' },
    { value: 'COMPLETED', label: 'Completed pending review ✅' },
    { value: 'REVIEWED', label: 'Reviewed 🤓' },
  ]

  const getStatusLabel = (status: string) => {
    const value = orderStatus.find((option) => option.value === status)
    return value ? value.label : status
  }

  const initiatePayment = async (paymentRequest: CustomPaymentRequest) => {
    const stripe = await stripePromise
    const response = await fetch(`${baseUrl}/api/payment/create-session`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(paymentRequest),
    })
    const { url } = await response.json()
    if (url) window.location.href = url
  }

  if (!task) return <p>Order not found</p>

  return (
    <div className="flex justify-center bg-gradient-to-r to-primary from-secondary-container h-full tracking-wide">
      {/* Edit Modal */}
      {showEditModal && (
        <div className="fixed inset-0 bg-secondary bg-opacity-50 flex justify-center items-center z-50">
          <EditOrderModal
            task={task}
            onUpdate={onUpdate}
            onClose={closeModal}
          />
        </div>
      )}

      {/* Payment Modal */}
      {showPaymentModal && (
        <div className="fixed inset-0 bg-secondary bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-on-primary rounded-lg shadow-lg w-full max-w-lg p-6">
            <h3 className="text-lg font-semibold mb-4">
              {task.status === 'IN_PROGRESS'
                ? 'Pay Remaining Balance'
                : 'Select Payment Type'}
            </h3>

            {task.status === 'IN_PROGRESS' ? (
              // Show "Pay Balance" button if deposit is already paid
              <button
                onClick={() =>
                  initiatePayment({
                    orderId: task.id,
                    amount: task.depositAmount * 100, // Remaining balance in cents
                    paymentType: 'balance',
                  })
                }
                className="px-4 py-2 bg-primary text-white rounded mr-[200px]"
              >
                Pay Balance (${task.depositAmount})
              </button>
            ) : (
              // Show deposit and full payment buttons if no deposit has been made
              <div className="flex justify-between mt-4">
                <button
                  onClick={() =>
                    initiatePayment({
                      orderId: task.id,
                      amount: task.depositAmount * 100, // Deposit amount in cents
                      paymentType: 'deposit',
                    })
                  }
                  className="px-4 py-2 bg-primary text-white rounded"
                >
                  Pay Deposit (${task.depositAmount})
                </button>
                <button
                  onClick={() =>
                    initiatePayment({
                      orderId: task.id,
                      amount: task.totalAmount * 100, // Full amount in cents
                      paymentType: 'full',
                    })
                  }
                  className="px-4 py-2 bg-primary text-white rounded"
                >
                  Pay Full Amount (${task.totalAmount})
                </button>
              </div>
            )}

            <button
              onClick={closeModal}
              className="mt-4 px-4 py-2 bg-error text-on-primary rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Delete Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 bg-secondary bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-on-primary rounded-lg shadow-lg w-full max-w-lg p-6">
            <h3 className="text-lg font-semibold mb-4">Delete Order</h3>
            <p>Are you sure you want to delete this order?</p>
            <div className="mt-4 flex justify-end space-x-4">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-secondary rounded text-on-primary"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  onDelete(task.id)
                  closeModal()
                }}
                className="px-4 py-2 bg-error text-on-primary rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="p-6 bg-on-primary shadow-sm rounded-lg m-5 max-w-[50vw]">
        <h2 className="text-2xl text-left font-semibold text-info">
          {getPaperTypeLabel(task.paperType)}
        </h2>
        <div className="">
          <p className="my-4 text-xl mr-2 font-semibold text-info">
            Instructions
          </p>
          <p
            className="my-4 text-lg"
            dangerouslySetInnerHTML={{
              __html: task.instructions,
            }}
          ></p>
        </div>
        <div className="bg-info px-3 py-4 rounded-md">
          <h3 className="text-xl font-semibold text-on-primary">
            Order details
          </h3>
          <ul className="my-1 flex-col gap-5">
            <li className="flex gap-2 justify-start items-center my-2 text-on-primary">
              <p className="font-semibold text-on-primary">Pages:</p>{' '}
              <p className="text-on-primary">{task.numberOfPages}</p>
            </li>
            <li className="flex gap-2 justify-start items-center my-2 text-on-primary">
              <p className="font-semibold">Due date:</p> <p>{task.dueDate}</p>
            </li>
            <li className="flex gap-2 justify-start items-center my-2 text-on-primary">
              <p className="font-semibold">Total cost:</p>{' '}
              <p>${task.totalAmount}</p>
            </li>
            <li className="flex gap-2 justify-start items-center my-2 text-on-primary">
              <p className="font-semibold">Amount to deposit:</p>{' '}
              <p>${task.depositAmount}</p>
            </li>
            <li className="flex gap-2 justify-start items-center my-2 text-on-primary">
              <p>{getStatusLabel(task.status)}</p>
            </li>
          </ul>
        </div>
        {/* Uploaded Files Table */}
        <div className="mb-6 mt-4">
          <h3 className="text-lg font-semibold text-info mb-2">
            Uploaded Files
          </h3>
          <div className="overflow-auto">
            <table className="min-w-full bg-secondary rounded-lg shadow-md text-on-primary">
              <thead>
                <tr className="bg-gray-300 border-on-primary border-[1px]">
                  <th className="p-3 text-left text-gray-800 font-semibold">
                    File Name
                  </th>
                  <th className="p-3 text-left text-gray-800 font-semibold">
                    Size
                  </th>
                  <th className="p-3 text-left text-gray-800 font-semibold">
                    Type
                  </th>
                  <th className="p-3 text-left text-gray-800 font-semibold">
                    URL
                  </th>
                </tr>
              </thead>
              <tbody>
                {task.uploadedFiles.length > 0 ? (
                  task.uploadedFiles.map((file) => (
                    <tr
                      key={file.id}
                      className="border-on-primary border-[1px]"
                    >
                      <td className="p-3 text-gray-700">{file.name}</td>
                      <td className="p-3 text-gray-700">{file.size}</td>
                      <td className="p-3 text-gray-700">{file.type}</td>
                      <td className="p-3">
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:text-blue-800"
                        >
                          Open
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="p-3 text-center text-gray-500">
                      No files uploaded.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* Buttons */}
        <div className="flex justify-center items-center gap-5">
          <button
            className="text-on-primary bg-warning px-2 py-1 rounded-sm flex gap-2 justify-center items-center"
            onClick={() => {
              setShowEditModal(true)
            }}
          >
            <FontAwesomeIcon icon={faPenToSquare} />
            <p>Update</p>
          </button>
          <button
            className="text-on-primary bg-primary px-2 py-1 rounded-sm flex gap-2 justify-center items-center"
            onClick={() => {
              setShowPaymentModal(true)
            }}
          >
            <FontAwesomeIcon icon={faCreditCard} />
            <p>Pay</p>
          </button>
          <button
            className="text-on-primary bg-error px-2 py-1 rounded-sm flex gap-2 justify-center items-center"
            onClick={() => {
              setShowDeleteModal(true)
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
            <p>Delete</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default OrderDetailPage
