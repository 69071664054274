import React, { useState } from 'react'
import { Order } from 'src/types'

interface EditOrderModalProps {
  task: Order
  onClose: () => void
  onUpdate: (updatedTask: Order) => void
}

const EditOrderModal: React.FC<EditOrderModalProps> = ({
  task,
  onClose,
  onUpdate,
}) => {
  const [formData, setFormData] = useState({ ...task })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target
    setFormData((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = () => {
    onUpdate(formData)
    onClose()
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-on-primary p-6 rounded-lg shadow-md w-96">
        <h2 className="text-xl font-bold mb-4">Edit Order</h2>
        <input
          type="text"
          name="paperType"
          value={formData.paperType}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-3"
        />
        <textarea
          name="instructions"
          value={formData.instructions}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-3"
          rows={3}
        />
        <input
          type="number"
          name="pages"
          value={formData.numberOfPages}
          onChange={handleChange}
          className="w-full p-2 border rounded mb-3"
        />
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-secondary rounded text-on-primary"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-primary-container text-white rounded"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditOrderModal
